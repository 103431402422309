import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Loadable from "@loadable/component";

import LoaderPage from "pages/LoaderPage";

import AppBar from "../../app-bar/MobileAppBar";
import Footer from "../../footer"; // Already lazy loaded
import Hero from "./Hero";

const Root = styled.div({});
const Content = styled.div((props) => ({
  maxWidth: 1184,
  margin: "0px auto",
}));

const Analytics = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileAnalyticsChunk" */ "pages/www/analytics/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const AutomatedNotifications = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileAutomatedNotificationsChunk" */ "pages/www/automated-waitlist-notifications/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
// const CaseStudies = Loadable(
//   () =>
//     import(
//       /* webpackChunkName: "WebsiteMobileCaseStudiesChunk" */ "pages/www/case-studies/MobileView.jsx"
//     ),
//   { fallback: () => <LoaderPage /> }
// );
const Customers = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileCustomersChunk" */ "pages/www/customers/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const FAQ = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileFAQChunk" */ "pages/www/faq/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Modules = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileModulesChunk" */ "pages/www/modules/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Pricing = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobilePricingChunk" */ "pages/www/pricing/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const RequestCallback = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileRequestCallbackChunk" */ "pages/www/request-callback/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const ReviewHq = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileReviewHqChunk" */ "pages/www/review-hq/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Testimonials = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteMobileTestimonialsChunk" */ "pages/www/testimonials/MobileView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);

export default function MobileView() {
  return (
    <Root>
      <AppBar />
      <Content>
        <Hero />
        <Testimonials />
        <Customers css={{ paddingTop: 40 }} />
        <Modules />
        <AutomatedNotifications css={{ paddingTop: 80, paddingBottom: 80 }} />
        <Analytics />
        <ReviewHq />
        <Pricing />
        {/* <CaseStudies /> */}
        <FAQ />
        <RequestCallback />
        <Footer />
      </Content>
    </Root>
  );
}
