import { render } from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loadable from "@loadable/component";

import HomePage from "pages/www/index.jsx";

import "./.well-known/assetlinks.json";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const App = Loadable(
  () => import(/* webpackChunkName: "App" */ "pages/App.jsx"),
  { fallback: () => <span>PLACEHOLDER SHOULD GO HERE</span> }
);
// const HomePage = Loadable(
//   () => import(/* webpackChunkName: "Website" */ "pages/www/index.jsx"),
//   { fallback: () => <span>PLACEHOLDER SHOULD GO HERE</span> }
// );

const createHistory = require("history").createBrowserHistory;

const history = createHistory();

const landingPaths = [
  "/sign-up",
  "/web-delivery",
  "/queue-management",
  "/reservations-overview",
  "/privacy",
  "/terms-of-service",
  "/contact-us",
  "/about-us",
  "/subscriptions",
];

function Outer() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path={landingPaths} component={HomePage} />
        <Route path="*" component={App} />
      </Switch>
    </Router>
  );
}

render(<Outer />, document.getElementById("root"));

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          if (
            window.confirm(
              "There is a new version of the app ready. Please reload to update."
            )
          ) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
