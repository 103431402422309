import React from "react";
import { Route } from "react-router-dom";
import Loadable from "@loadable/component";

import HomePage from "pages/www/home-page";
import LoaderPage from "pages/LoaderPage";

// const HomePage = Loadable(
//   () =>
//     import(
//       /* webpackChunkName: "HomePageChunk" */ "pages/www/home-page/index.jsx"
//     ),
//   { fallback: () => <LoaderPage /> }
// );
const ReservationsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ReservationsLandingPage" */ "pages/www/reservations-landing/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const QueueManagementPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "QueueManagementPage" */ "pages/www/waitlist-landing/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const DeliveryPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "DeliveryPage" */ "pages/www/delivery-landing/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const SignUpPage = Loadable(
  () =>
    import(/* webpackChunkName: "SignUpPage" */ "pages/www/sign-up/index.jsx"),
  { fallback: () => <LoaderPage /> }
);
const SuccessPage = Loadable(
  () =>
    import(/* webpackChunkName: "SuccessPage" */ "pages/www/sign-up/SuccessPage.jsx"),
  { fallback: () => <LoaderPage /> }
);
const TermsOfServicePage = Loadable(
  () =>
    import(
      /* webpackChunkName: "TermsOfServicePage" */ "pages/www/TermsOfServicePage.jsx"
    ),
  { fallback: <LoaderPage /> }
);
const PrivacyPage = Loadable(
  () =>
    import(/* webpackChunkName: "PrivacyPage" */ "pages/www/PrivacyPage.jsx"),
  { fallback: <LoaderPage /> }
);
const AboutUsPage = Loadable(
  () =>
    import(/* webpackChunkName: "AboutUsPage" */ "pages/www/AboutUsPage.jsx"),
  { fallback: <LoaderPage /> }
);
const ContactUsPage = Loadable(
  () =>
    import(
      /* webpackChunkName: "ContactUsPage" */ "pages/www/ContactUsPage.jsx"
    ),
  { fallback: <LoaderPage /> }
);

export default function AppRoutes() {
  return (
    <>
      <Route exact path="/" component={HomePage} />
      <Route
        exact
        path="/subscriptions/:productId/:priceId/sign-up"
        component={SignUpPage}
      />
       <Route
          exact
          path="/subscriptions/sign-up/success"
          component={SuccessPage}
        />
      <Route exact path="/reservations-overview" component={ReservationsPage} />
      <Route exact path="/queue-management" component={QueueManagementPage} />
      <Route exact path="/web-delivery" component={DeliveryPage} />
      <Route exact path="/privacy" component={PrivacyPage} />
      <Route exact path="/terms-of-service" component={TermsOfServicePage} />
      <Route exact path="/contact-us" component={ContactUsPage} />
      <Route exact path="/about-us" component={AboutUsPage} />
    </>
  );
}
