import React from "react";

import { useBreakpoints } from "util/BreakpointProvider";

import DesktopView from "./DesktopView";
import MobileView from "./mobile";
import TabletView from "./tablet";

export default function HomePage() {
  const bp = useBreakpoints();

  if (bp.lessThan.md) return <MobileView />;
  if (bp.lessThan.lg) return <TabletView />;
  return <DesktopView />;
}
