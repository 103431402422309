import React from "react";

export const DialogContext = React.createContext({
  setCurrentDialog: () => {},
});

export default function DialogProvider({ children }) {
  const [currentDialog, setCurrentDialog] = React.useState(null);

  return (
    <DialogContext.Provider value={{ currentDialog, setCurrentDialog }}>
      {children}
    </DialogContext.Provider>
  );
}

export const useDialogManager = () => React.useContext(DialogContext);
