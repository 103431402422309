import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

import RestoGuruImg from "pages/www/assets/resto-guru-logo.svg";
import { useBreakpoints } from "util/BreakpointProvider";

const Image = styled.img({
  cursor: "pointer",
  userSelect: "none",
});

export default function Logo() {
  const bp = useBreakpoints();
  const width = bp.lessThan.md ? 120 : 136;

  const handleClick = () => {
    window.location.href = "/";
  };

  return <Image onClick={handleClick} src={RestoGuruImg} css={{ width }} />;
}
