import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";

import { BOOK_DEMO_URL } from "pages/www/util";

import { maxWidth } from "../styles/constants";
import DesktopNav from "./DesktopNav";
import Logo from "./Logo";

const Root = styled.div((props) => ({
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 3,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: props.theme.bp.lessThan.md ? "16px 0px" : "24px 0px",
  paddingLeft: props.theme.bp.lessThan.xl ? 24 : 0,
  paddingRight: props.theme.bp.lessThan.xl ? 24 : 0,
  background: "rgba(0, 0, 0, 0.68)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(25px)",
  WebkitBackdropFilter: "blur(25px)",
  WebkitTransition: "height 200ms ease",
  transition: "height 200ms ease",
}));
const Container = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth,
  ...(props.theme.bp.lessThan.lg && { padding: "0px 18px 0px 24px" }),
}));

export default function AppBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(!open);
  };

  const handleClick = () => {
    window.open(BOOK_DEMO_URL, "_blank");
  };

  return (
    <Root>
      <Container>
        <Logo />
        <DesktopNav
          open={open}
          toggleDrawer={toggleDrawer}
          handleClick={handleClick}
        />
      </Container>
    </Root>
  );
}
