import React from "react";
import isEqual from "lodash/isEqual";

import BreakpointContext from "./BreakpointContext";

const breakpoints = {
  xs: 480,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  xxl: 1920,
  infinity: null,
};

const INITIAL_VALUE = {
  is: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  },
  greaterThan: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  },
  lessThan: {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    xxl: false,
    infinity: false,
  },
};

function useBp() {
  const [bp, setBp] = React.useState(INITIAL_VALUE);

  React.useEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      // setHeight(window.innerHeight);
      let bpIdx = 0;
      const values = Object.values(breakpoints);
      const bpKeys = Object.keys(breakpoints);
      while (width > values[bpIdx]) {
        bpIdx = bpIdx + 1;
      }
      const isKey = bpKeys[bpIdx];
      const lessThanKeys = [...bpKeys];
      let greaterThanKeys = lessThanKeys.splice(bpIdx);
      greaterThanKeys.shift(); // Remove the isKey
      const lessThan = {};
      lessThanKeys.forEach((key) => (lessThan[key] = false));
      greaterThanKeys.forEach((key) => (lessThan[key] = true));
      lessThan[isKey] = false;
      const greaterThan = {};
      lessThanKeys.forEach((key) => (greaterThan[key] = true));
      greaterThanKeys.forEach((key) => (greaterThan[key] = false));
      greaterThan[isKey] = false;
      const is = {};
      bpKeys.forEach((key, idx) => (is[key] = idx === bpIdx ? true : false));

      const newBp = {
        is,
        lessThan,
        greaterThan,
        breakpoints,
      };
      if (!isEqual(bp, newBp)) {
        setBp(newBp);
      }
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [bp]);

  return bp;
}

export default function BreakpointProvider({ children }) {
  const bp = useBp();

  return (
    <BreakpointContext.Provider value={{ bp }}>
      {children}
    </BreakpointContext.Provider>
  );
}

export function useBreakpoints() {
  return React.useContext(BreakpointContext).bp;
}
