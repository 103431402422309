import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";

import { BOOK_DEMO_URL } from "pages/www/util";

import { maxWidth } from "../styles/constants";
import MobileNavDrawer from "./MobileNavDrawer";
import Logo from "./Logo";

const glassMorphic = {
  background: "rgba(0, 0, 0, 0.68)",
  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(60px)",
  WebkitBackdropFilter: "blur(60px)",
  zIndex: 3,
};
const StyledAccordion = styled(Accordion)((props) => ({
  ...glassMorphic,
  alignItems: "center",
  justifyContent: "center",
  ".MuiAccordionSummary-content": {
    margin: 0,
  },
  ".MuiAccordionSummary-content.Mui-expanded": {
    margin: 0,
  },
  backgroundColor: "unset",
  //   padding: props.theme.bp.lessThan.md ? "16px 0px" : "24px 0px",
}));
const StyledAccordionSummary = styled(AccordionSummary)((props) => ({
  ...glassMorphic,
  height: 80,
  padding: "0px 32px",
}));
const StyledAccordionDetails = styled(AccordionDetails)({
  ...glassMorphic,
  paddingBottom: 48,
});

const Root = styled.div((props) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2,
}));
const Container = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  maxWidth,
}));
const StyledMenuIcon = styled(MenuIcon)((props) => ({
  color: props.theme.palette.text.primary,
  transform: "rotate(0deg)",
  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
}));
const StyledCloseIcon = styled(CloseIcon)((props) => ({
  color: props.theme.palette.text.primary,
  transform: "rotate(180deg)",
  transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
}));

export default function AppBar() {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    window.open(BOOK_DEMO_URL, "_blank");
  };

  return (
    <Root>
      <StyledAccordion onChange={() => setOpen(!open)} expanded={open}>
        <StyledAccordionSummary
          expandIcon={open ? <StyledCloseIcon /> : <StyledMenuIcon />}
        >
          <Container>
            <Logo />
          </Container>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <MobileNavDrawer
            handleClick={handleClick}
            isOpen={open}
            setOpen={setOpen}
          />
        </StyledAccordionDetails>
      </StyledAccordion>
    </Root>
  );
}
