import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import Loadable from "@loadable/component";

import LoaderPage from "pages/LoaderPage";

import AppBar from "../app-bar";
import Hero from "./hero";
import Footer from "../footer"; // Footer already lazy loaded

const Root = styled.div({});
const Content = styled.div((props) => ({
  maxWidth: 1184,
  margin: "0px auto",
}));

const Analytics = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopAnalyticsChunk" */ "pages/www/analytics/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const AutomatedNotifications = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopAutomatedNotificationsChunk" */ "pages/www/automated-waitlist-notifications/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
// const CaseStudies = Loadable(
//   () =>
//     import(
//       /* webpackChunkName: "WebsiteDesktopCaseStudiesChunk" */ "pages/www/case-studies/DesktopView.jsx"
//     ),
//   { fallback: () => <LoaderPage /> }
// );
const Customers = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopCustomersChunk" */ "pages/www/customers/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const FAQ = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopFAQChunk" */ "pages/www/faq/DesktopView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Modules = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopModulesChunk" */ "pages/www/modules/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Pricing = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopPricingChunk" */ "pages/www/pricing/DesktopView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const RequestCallback = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopRequestCallbackChunk" */ "pages/www/request-callback/DesktopView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const ReviewHq = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopReviewHqChunk" */ "pages/www/review-hq/index.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);
const Testimonials = Loadable(
  () =>
    import(
      /* webpackChunkName: "WebsiteDesktopTestimonialsChunk" */ "pages/www/testimonials/DesktopView.jsx"
    ),
  { fallback: () => <LoaderPage /> }
);

export default function DesktopView() {
  return (
    <Root>
      <AppBar />
      <Content>
        <Hero />
        <Customers css={{ paddingTop: 40, paddingBottom: 0 }} />
        <Testimonials />
        <Modules />
        <AutomatedNotifications />
        <Analytics />
        <ReviewHq />
        <Pricing />
        {/* <CaseStudies /> */}
        <FAQ />
        <RequestCallback />
        <Footer />
      </Content>
    </Root>
  );
}
