import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import DialogContentText from "@material-ui/core/DialogContentText";

import Dialog from "./Dialog";

const StyledText = styled(DialogContentText)((props) => ({
  ...props.theme.typography.body1,
  lineHeight: 1.5,
  marginBottom: 0,
}));

const INITIAL_STATE = {
  title: "",
  content: "",
  open: false,
  onConfirm: null,
  onCancel: null,
  buttonText1: null,
  buttonText2: null,
};
const ModalContext = React.createContext(INITIAL_STATE);

export default function ModalProvider({ children }) {
  const [state, setState] = React.useState(INITIAL_STATE);
  const {
    title,
    content,
    open,
    onConfirm,
    onCancel,
    onClose,
    buttonText1,
    buttonText2,
  } = state;

  const openModal = ({
    title,
    content,
    onConfirm,
    onCancel,
    onClose,
    buttonText1,
    buttonText2,
  }) => {
    setState({
      content,
      onConfirm,
      onCancel,
      onClose,
      open: true,
      title,
      buttonText1,
      buttonText2,
    });
  };

  const closeModal = () => {
    setState({ open: false });
    setState(INITIAL_STATE);
    onClose?.();
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {open && (
        <Dialog
          title={title}
          open={open}
          handleConfirm={onConfirm}
          handleCancel={onCancel}
          onClose={closeModal}
          agreeText={buttonText1}
          disagreeText={buttonText2}
        >
          <StyledText>{content}</StyledText>
        </Dialog>
      )}
      {children}
    </ModalContext.Provider>
  );
}

export const useModal = () => React.useContext(ModalContext);
