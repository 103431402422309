import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";

import { gradientColor2 } from "../styles/constants";

const Link = styled.a((props) => ({
  ...props.theme.typography.body1,
  textDecoration: "none",
  ...(props.active && {
    color: gradientColor2,
  }),
  "@media (hover: hover) and (pointer: fine)": {
    "&:hover": {
      color: "white",
    },
  },
}));

export default function DesktopNav({ handleClick }) {
  const currentURL = window.location.href;
  return (
    <div css={{ display: "flex", gridGap: "0px 32px", alignItems: "center" }}>
      {/* <Link href="/web-delivery">Online Ordering</Link> */}
      <Link
        href="/reservations-overview"
        active={currentURL.includes("reservations-overview")}
      >
        Reservations
      </Link>
      <Link
        href="/queue-management"
        active={currentURL.includes("queue-management")}
      >
        Queue Management
      </Link>
      <Link href="#pricing">Pricing</Link>
      <Button onClick={handleClick} variant="contained">
        Book a demo
      </Button>
    </div>
  );
}
