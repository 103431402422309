/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";

import { gradientColor1, gradientColor2 } from "./constants";

export const ImageSection = styled.div((props) => ({
  display: "grid",
  ...(props.theme.bp.greaterThan.md && {
    gridTemplateColumns: "1fr 1fr",
    paddingBottom: 140,
    paddingTop: 140,
  }),
}));
export const Section = styled.div((props) => ({
  padding: props.theme.bp.lessThan.lg ? "80px 0px" : "140px 0px",
}));

export const ImageContainer = styled.div((props) => ({
  background: `-webkit-linear-gradient(${gradientColor1}, ${gradientColor2})`,
  padding: 6,
  height: "min-content",
  borderRadius: 8,
  width: "auto",
  display: "flex",
  zIndex: 1,
}));

const StyledButton = styled(Button)((props) => ({
  ...(props.theme.bp.lessThan.lg && {
    width: 225,
    height: 45,
    margin: "0 0 64px",
  }),
}));

export function GetStartedButton(props) {
  const handleClick = () => {
    const pricingSection = document.getElementById("pricing");
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <StyledButton variant="contained" onClick={handleClick} {...props}>
      Get started
    </StyledButton>
  );
}
