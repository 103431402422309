import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import CssBaseline from "@material-ui/core/CssBaseline";

import DialogProvider from "common/dialog/DialogContext";
import BreakpointProvider, { useBreakpoints } from "util/BreakpointProvider";
import ModalProvider from "util/ModalContext";

import "./index.scss";
import AppRoutes from "./AppRoutes";
import getTheme from "./theme";

function useTheme() {
  const bp = useBreakpoints();
  return getTheme(bp);
}

function WWW() {
  const theme = useTheme();

  return (
    <EmotionThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <ModalProvider>
            <CssBaseline />
            <AppRoutes />
          </ModalProvider>
        </DialogProvider>
      </ThemeProvider>
    </EmotionThemeProvider>
  );
}

export default function Wrapper() {
  return (
    <BreakpointProvider>
      <WWW />
    </BreakpointProvider>
  );
}
