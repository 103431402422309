import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import CircularProgress from "@material-ui/core/CircularProgress";

const Root = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 50,
  backgroundColor: "#121212",
  zIndex: 1300, //props.theme.zIndex.modal,
});
export default function LoaderPage() {
  return (
    <Root>
      <CircularProgress size={50} />
    </Root>
  );
}
