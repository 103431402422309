import React from "react";

import { useBreakpoints } from "util/BreakpointProvider";

import DesktopAppBar from "./DesktopAppBar";
import MobileAppBar from "./MobileAppBar";

export default function AppBar() {
  const bp = useBreakpoints();

  if (bp.lessThan.lg) return <MobileAppBar />;
  return <DesktopAppBar />;
}
