import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@material-ui/core";

import { gradientColor2 } from "../styles/constants";

const Link = styled.a((props) => ({
  ...props.theme.typography.body1,
  textDecoration: "none",
  fontSize: "1.2rem",
  ...(props.active && {
    color: gradientColor2,
  }),
}));

const MobileNavRoot = styled.div((props) => ({
  width: "100%",
  WebkitTransition: "opacity 300ms ease, visibility 300ms ease",
  transition: "opacity 300ms ease, visibility 300ms ease",
  maxWidth: "100vw",
  ...(props.isOpen
    ? {
        opacity: 1,
        visibility: "visible",
      }
    : {
        opacity: 0,
        visibility: "hidden",
      }),
}));

const MobileNavDrawerWrapper = styled.div((props) => ({
  display: "flex",
  gridGap: "72px 0px",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  padding: "88px 40px 0",
}));

export default function MobileNavDrawer({ isOpen, handleClick, setOpen }) {
  const currentURL = window.location.href;

  return (
    <MobileNavRoot isOpen={isOpen}>
      <MobileNavDrawerWrapper>
        {/* <Link href="/web-delivery">Online Ordering</Link> */}
        <Link
          href="/reservations-overview"
          active={currentURL.includes("reservations-overview")}
        >
          Reservations
        </Link>
        <Link
          href="/queue-management"
          active={currentURL.includes("queue-management")}
        >
          Queue Management
        </Link>
        <Link href="#pricing" onClick={() => setOpen(false)}>
          Pricing
        </Link>
        <Button onClick={handleClick} variant="contained">
          Book a demo
        </Button>
      </MobileNavDrawerWrapper>
    </MobileNavRoot>
  );
}
