import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import orange from "@material-ui/core/colors/orange";

import styles from "static/colors.scss";

const background = {
  0: "#121212",
  1: "#1E1E1E",
  2: "#232323",
  3: "#252525",
  4: "#272727",
  6: "#2C2C2C",
  8: "#2F2F2F",
  12: "#333333",
  16: "#353535",
  24: "#383838",
  default: "#121212",
  paper: "#2C2C2C",
  light: "#252525",
  lighter: "#666E75",
  main: "#232323",
  dark: "#121212",
};

const darkTheme = {
  palette: {
    background,
    colors: {
      blue: blue[200],
      green: green[200],
      red: red[200],
      orange: orange[200],
    },
    iconColor: "#879096",
    primary: {
      light: styles.darkSecondary,
      main: styles.darkSecondary,
      dark: styles.darkSecondary,
      contrastText: styles.darkPrimaryMain,
    },
    secondary: {
      light: orange[100],
      main: orange[200],
      dark: orange[500],
    },
    success: {
      main: green[200],
    },
    error: {
      main: "#CF6679",
    },
    text: {
      primary: styles.darkTextPrimary,
      secondary: styles.darkTextSecondary,
      tertiary: styles.darkTextTertiary,
      disabled: styles.darkTextDisabled,
    },
    divider: "rgba(255, 255, 255, 0.06)",
    green: green[200],
    red: red[200],
    orange: orange[200],
  },
  baseline: {
    body: {
      letterSpacing: 0.5,
      backgroundColor: "black",
    },
  },
};

export default darkTheme;
