export function applyCoupon(coupon, amount) {
  const percent_off = coupon.percent_off;
  const amount_off = coupon.amount_off;

  if (percent_off) return amount * (1 - percent_off / 100);
  if (amount_off) return amount - amount_off / 100;
  return amount;
}

const liveFeatures = (bp) => [
  {
    productId: "prod_JPUSad8d3iwsIV",
    name: "Express",
    features: [
      "Digital storefront",
      "Order management",
      "Dashboard and analytics",
      "Marketing",
      "Online payments",
      "Real-time order tracking",
      ...(bp?.lessThan?.lg
        ? []
        : ["Menu management", "Delivery zones", "Driver app"]),
      "... and more!",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1ImfTdFx2jZRS4JxVFoIOqjd",
        price: "199",
      },
      {
        interval: "year",
        priceId: "price_1ImfTdFx2jZRS4JxO5viCVK8",
        price: "1920",
      },
    ],
  },
  {
    productId: "prod_Ja0nKROTUlydwB",
    name: "Guest360",
    features: [
      "Table management",
      "Feedback management",
      "Marketing",
      "Guest database",
      "Reservation prepayments",
      "Online waitlist",
      ...(bp?.lessThan?.lg
        ? []
        : [
            "Online bookings",
            "Booking widgets",
            "Automated booking reminders",
            "WhatsApp, SMS and robocall notifications",
          ]),
      "... and more!",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1IwqoyFx2jZRS4Jxm8BUDyZw",
        price: "600",
      },
      {
        interval: "year",
        priceId: "price_1Iwqm4Fx2jZRS4Jx9WJ6KqQL",
        price: "5760",
      },
    ],
  },
  {
    productId: "prod_O2RB0iP1FVseJP",
    name: "Guest360+Express",
    features: [
      "Everything included in Guest 360 plus",
      "Everything included in Express",
      "Task management",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1NGMJ6Fx2jZRS4JxIaMCoMie",
        price: "749",
      },
      {
        interval: "year",
        priceId: "price_1NGMJ6Fx2jZRS4JxFW7CUBpd",
        price: "7680",
      },
    ],
  },
];

const testFeatures = (bp) => [
  {
    productId: "prod_OfioDSqGCCpH1v",
    name: "Express",
    features: [
      "Digital storefront",
      "Order management",
      "Dashboard and analytics",
      "Marketing",
      "Online payments",
      "Real-time order tracking",
      ...(bp?.lessThan?.lg
        ? []
        : ["Menu management", "Delivery zones", "Driver app"]),
      "... and more!",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1NsNN2Fx2jZRS4JxEoCjy5qD",
        price: "199",
      },
      {
        interval: "year",
        priceId: "price_1NsNN2Fx2jZRS4JxWW8CqsmT",
        price: "1920",
      },
    ],
  },
  {
    productId: "prod_Ofil9Aon6dZpkD",
    name: "Guest360",
    features: [
      "Table management",
      "Feedback management",
      "Marketing",
      "Guest database",
      "Reservation prepayments",
      "Online waitlist",
      ...(bp?.lessThan?.lg
        ? []
        : [
            "Online bookings",
            "Booking widgets",
            "Automated booking reminders",
            "WhatsApp, SMS and robocall notifications",
          ]),
      "... and more!",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1NsNKGFx2jZRS4Jx1mP8f5an",
        price: "600",
      },
      {
        interval: "year",
        priceId: "price_1NsNKGFx2jZRS4JxBkMYNkg5",
        price: "5760",
      },
    ],
  },
  {
    productId: "prod_Ofi5rbTTn2D7IV",
    name: "Guest360+Express",
    features: [
      "Everything included in Guest 360 plus",
      "Everything included in Express",
      "Task management",
    ],
    intervals: [
      {
        interval: "month",
        priceId: "price_1NsMfDFx2jZRS4JxaGzoDPTU",
        price: "749",
      },
      {
        interval: "year",
        priceId: "price_1NsMfDFx2jZRS4JxPy0rTCUg",
        price: "7680",
      },
    ],
  },
];

export const getFeaturesData = (bp) => {
  if (process.env.REACT_APP_TARGET === "prod") return liveFeatures(bp);
  return testFeatures(bp);
};

export const getProductIdWithYearlyPriceId = (name) => {
  const product = getFeaturesData().find((product) => product.name === name);
  const yearlyPriceId = product.intervals.find(
    (item) => item.interval === "year"
  ).priceId;
  return { productId: product.productId, priceId: yearlyPriceId };
};

export const BOOK_DEMO_URL =
  "https://calendly.com/ruben-morgan/resto-guru-demo";
