import React from "react";
/** @jsxImportSource @emotion/react */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MuiButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledCircularProgress = styled(CircularProgress)((props) => ({
  color: "white",
}));
const LowEmphasisButton = styled(ButtonBase)((props) => ({
  backgroundColor: "#3F3F3F",
  color: props.theme.palette.text.primary,
  fontWeight: 400,
}));
const StyledButton = styled(ButtonBase)({
  borderRadius: 100,
  boxShadow: "none",
});

function RoundedButton(props) {
  return <StyledButton color="primary" {...props} variant="contained" />;
}

function ButtonBase({ children, disabled, loading, ...others }) {
  const newProps = { ...others };
  if (disabled) newProps["disabled"] = disabled;
  if (loading) newProps["disabled"] = true;
  return (
    <MuiButton {...newProps}>
      {loading ? (
        <StyledCircularProgress className="Button-circularProgress" size={24} />
      ) : (
        children
      )}
    </MuiButton>
  );
}

export default function Button(props) {
  if (props.variant === "rounded") return <RoundedButton {...props} />;
  if (props.variant === "low-emphasis") return <LowEmphasisButton {...props} />;
  return <ButtonBase {...props} />;
}
